<template>
  <div>
    <van-empty description="暂无相关充值订单" v-if="list == undefined || list.length == 0 || list[0] == undefined">
  <van-button round class="bottom-button" plain color="#482d00" :to="'/recharge?id='+item_id">立即充值</van-button>
</van-empty>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-else>
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="onLoad"
    style="height:100%;width: 100%;"
  >
    <view
				style="border-radius: 10px;background-color: #FFFFFF;padding: 15px;margin-top: 1px;margin-bottom:11px;;display: flex;flex-direction: column;align-items: center;justify-content: space-between;width: 90%;"
				v-for="(item,index) in list" :key="index">
				<view
					style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;width: 100%;">
					<view class="u-font-xs" style="color: rgb(153,155,154);" @click="copyData(item.orderNo)">
						单号:{{item.orderNo}}<van-icon name="https://img.alicdn.com/imgextra/i3/573804794/O1CN01J3n3jh1lHhS7MyhU4_!!573804794.png" />
					</view>
					<view class="u-font-xs" style="color: rgb(153,155,154);">
						{{item.create_time}}
					</view>
				</view>
				<van-divider dashed/>
				<view style="display: flex;align-items: center;flex-direction: row;width: 100%;justify-content: space-between;">
					<view style="display: flex;flex-direction: column;">
						<view style="display: flex;flex-direction: row;align-items: center;font-size: 15px !important;" class="u-font-xs">
							{{item.product_title}}
						</view>
						<view style="display: flex;flex-direction: row;align-items: baseline;margin: 12px 0px 0px;">
							<view class="u-font-xs" style="color: rgb(153,155,154);">
								充值账号:
							</view>
							<view class="u-font-xl" style="display: flex;flex-direction: row;align-items: center;padding-left: 5px;" @click="copyData(item.recharge_no)">
								{{item.recharge_no}}<van-icon name="https://img.alicdn.com/imgextra/i3/573804794/O1CN01J3n3jh1lHhS7MyhU4_!!573804794.png" />
							</view>
						</view>
            <view style="display: flex;flex-direction: row;align-items: baseline;margin: 12px 0px 0px;">
							<view class="u-font-xs" style="color: rgb(153,155,154);">
								支付金额:
							</view>
              <view class="u-font-xs">
								￥
							</view>
							<view class="u-font-xl" style="display: flex;flex-direction: row;align-items: center;padding-left: 5px;">
								{{item.pay_money || item.money}}
							</view>
						</view>
					</view>
					<view style="display: flex;align-items: center;flex-direction: row;">
						<van-tag type="success" size="medium" v-if="item.status == 1">交易成功</van-tag>
						<van-tag type="danger" size="medium" @click="showDialog(item.statusmsg)" v-else-if="item.status == -1">交易失败 <van-icon name="warning-o" style="margin-left: 3px;"/></van-tag>
						<van-tag type="warning" size="medium" @click="showDialog('系统正在确认支付结果，请稍查看')" v-else-if="item.status == 2">支付中 <van-icon name="warning-o" style="margin-left: 3px;"/></van-tag>
						<van-tag type="warning" size="medium" color="gray" v-else-if="item.status == 0">未支付</van-tag>
						<van-tag type="warning" size="medium" color="gray" v-else>未知</van-tag>
					</view>
				</view>
			</view>
  </van-list>
</van-pull-refresh>
  </div>
</template>

<script>
import { List, PullRefresh, Toast, Empty, Divider, Tag, Icon, Dialog, Button } from 'vant';
import { reactive, toRefs, ref, onMounted } from 'vue';
import { get } from "@/utils/request";
import { useRouter } from "vue-router";
import useClipboard from 'vue-clipboard3'
const { aplus_queue } = window;

export default {
  components: {
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [List.name]: List,
    [Empty.name]: Empty,
    [Divider.name]: Divider,
    [PullRefresh.name]: PullRefresh
  },
setup() {
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const router = useRouter();
    const { toClipboard } = useClipboard()
    const state = reactive({
      list: [],
      page: 1,
      item_id: ''
    });

    onMounted(() => {
      const item_id = router.currentRoute.value.query.id;
      state.item_id = item_id
      getData(item_id);
      const pv_user_id = localStorage.getItem('pv_user_id')
      const pv_user_name = localStorage.getItem('pv_user_name')
      aplus_queue.push({
          action: 'aplus.sendPV',
            arguments: [{
                is_auto: false
            }, {
                pv_user_id: pv_user_id,
                pv_user_name: pv_user_name
            }]
          });
    });
  function showDialog(msg){
    Dialog({ message: msg, title: '提示' });
  }
  const copyData = async (data) => {
      try {
        await toClipboard(data)
        Toast.success('复制成功')
      } catch (e) {
        Toast.fail('复制失败');
      }
    }
  function getData() {
    const item_id = router.currentRoute.value.query.id;
      get("getOrderList", { id: item_id, page: state.page }).then((response) => {
        if (response.code == 200) {
          if (state.list.length == 0) {
            state.list = response.data
          }else{
            state.list = state.list.concat(response.data)
          }
          loading.value = false;
        if (response.data.length < 12) {
          finished.value = true;
        }else{
          state.page = state.page + 1
        }
        }
      });
    }

    const onLoad = () => {
        if (refreshing.value) {
          state.list = [];
          refreshing.value = false;
        }
        getData()
    };

    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      state.page = 1
      onLoad();
    };

    return {
      ...toRefs(state),
      onLoad,
      loading,
      finished,
      copyData,
      showDialog,
      onRefresh,
      refreshing,
    };

  },
};
</script>

<style lang="less">
.u-font-xs {
    font-size: 13px !important;
}.van-divider {
    width: 100%;
}.van-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
</style>
